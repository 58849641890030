<template>
  <div>
    <maca-titulo-pagina
      icono="el-icon-user"
      nombrePagina="Permisos: Operaciones"
    ></maca-titulo-pagina>
    <el-card :body-style="{ padding: '25px' }">
      <div class="aiuda">
        <span style="font-size: 24px; font-weight: 600"></span>
        <el-button class="button" @click="$router.go(-1)"
          >Volver atrás</el-button
        >
      </div>
      <!-- <el-divider /> -->
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Permiso" prop="descripcion"></el-table-column>
          <el-table-column label="Habilitado" width="100px">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.habilitado"
                :active-value="1"
                :inactive-value="0"
                @change="toogleHabilitar(props.row)"
              ></el-switch>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "permisos-usuario",
  data() {
    return {
      urlTabla: "/permiso/obtenerTodosPantallaRol",
      paramsTabla: {
        pantallaID: this.$route.params.id,
        rolID: this.$route.params.rolID,
      },
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null,
      nombreRol: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    async toogleHabilitar(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: this.$route.params.rolID, permisoID: fila.id };
      let respuestaApi = null;

      if (fila.habilitado) {
        respuestaApi = await this.$maca.api.post("/rol/crearPermiso", params);
      } else {
        respuestaApi = await this.$maca.api.post(
          "/rol/eliminarPermiso",
          params
        );
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
};
</script>

<style scoped>
.aiuda {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
